import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Theme,
  Grid
} from '@material-ui/core';

import { Button } from 'common/components';
import { sendGoalAccessRequest } from 'slices/goal/action';
import { sendJournalAccessRequest } from 'slices/journey/action';
import { sendSafetyAccessRequest } from 'slices/safety/action';
import { sendNetworkAccessRequest } from 'slices/network/action';
import { sendMyStoryAccessRequest } from 'slices/story/action';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '20px'
  },
  messageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
    maxWidth: '500px'
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: '70%'
    // }
  },
  info: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '127.69%',
    fontSize: '18px',
    textAlign: 'left',
    color: '#37474F',
    // [theme.breakpoints.up('md')]: {
    //   fontSize: '22px',
    //   lineHeight: '23px'
    // }
  },
  buttonContainer: {
    zIndex: 200,
    width: '100%',
    margin: '30px 0'
  },
  mailContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '127.69%',
    fontSize: '18px',
    color: '#37474F'
  }
}));

type Props = {
  type: 'Goals' | 'Journey' | 'Story' | 'Safety Plan' | 'Network';
};

const AccessRequest: React.FC<Props> = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  /** Theme */
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  /** Dialog */
  const [open, setOpen] = useState(false);
  const [openButton, setOpenButton] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleButtonOpen = () => {
    setOpenButton(true);
  };

  const handleClick = () => {
    handleClickOpen();

    type === 'Goals' && dispatch(sendGoalAccessRequest());
    type === 'Journey' && dispatch(sendJournalAccessRequest());
    type === 'Safety Plan' && dispatch(sendSafetyAccessRequest());
    type === 'Network' && dispatch(sendNetworkAccessRequest());
    type === 'Story' && dispatch(sendMyStoryAccessRequest());

    setTimeout(() => {
      handleClose();
    }, 100);
  };

  const loaderDialog = (
    <Dialog
      open={open}
      keepMounted
      fullScreen={fullScreen}
      onClose={handleClose}>
      <DialogContent style={{ padding: '0' }}>{/* <Loader /> */}</DialogContent>
    </Dialog>
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container justify="center">
          {/* {matchesSM ? undefined : <Grid item xs={1} sm={2} md={4} />} */}
          <Grid item xs={12} container justify="center">
            <div className={classes.messageContainer}>
              <div className={classes.info}>
                <p>Seems like there’s nothing to see here!</p>
                <p>There are two possible reasons: </p>
                <p style={{ marginTop: '1em' }}>
                  1. You don’t have access to{' '}
                  {` ${sessionStorage.getItem('FirstName')}’s ${type}`}
                </p>
                <p>
                  2. {` ${sessionStorage.getItem('FirstName')}’s ${type}`} is
                  still blank
                </p>{' '}
                <p style={{ marginTop: '1em' }}>
                  Use the button to let{' '}
                  {` ${sessionStorage.getItem('FirstName')}`} know you want to
                  know more!
                </p>{' '}
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  type="anotherButton"
                  click={() => {
                    handleClick();
                    handleButtonOpen();
                  }}>
                  I want to see this
                </Button>
              </div>
              {openButton ? (
                <div className={classes.mailContainer}>
                  We have sent your request
                </div>
              ) : (
                undefined
              )}
            </div>
          </Grid>
          {/* <Grid item xs={3} sm={4} container justify="flex-end"></Grid> */}
        </Grid>
      </div>
      {open && loaderDialog}
    </Fragment>
  );
};

export default AccessRequest;
