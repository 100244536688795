import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { Profile } from 'types/profile';
//Jorge Avendano E03 280920 Email redirection begin
import { selectPerson } from 'slices/people/action';
import { Person } from 'types/people';
import { fetchAllFocusAreas } from 'slices/other/action';
import { fetchGoals } from 'slices/goal/action'; //Hammad Tahir - goal help email redirection - 101021
//Jorge Avendano E03 end

//** ASYNC FUNCS */
export const startSession = (history: any): AppThunk => async dispatch => {
  try {
    await callProfileCheckApi()
      .then(async response => {
        // console.log(response);
        await callProfileReadApi();
        
        // history.push('/home');
        dispatch(redirectAction(history));//Jorge Avendano E03 280920 Email redirection
      })
      .catch(async error => {
        // console.log(error);
        await callProfileSetUpApi();
        await callProfileReadApi();

        //history.push('/home');
        console.log('ioorsh redirects');
        dispatch(redirectAction(history));//Jorge Avendano E03 280920 Email redirection
      });
  } catch (err) {
    history.push('/');//Jorge Avendano T43.3 270720 Redirects to the index page in case of any issue
    // dispatch(failed(err.toString()));
  }
};

//Jorge Avendano E03 280920 Email redirection
export const redirectAction = (history: any): AppThunk => async dispatch => {
  try {
    if (sessionStorage.getItem('target') !== null &&
      sessionStorage.getItem('target') !== '') {
      if (sessionStorage.getItem('userId') !== null &&
        sessionStorage.getItem('userId') !== '') {

        const people = await callRecoveryPlanListApi();

        let target = sessionStorage.getItem('target');
        let userId = sessionStorage.getItem('userId');

        dispatch(fetchAllFocusAreas());

        if (target == 'goals') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/goals/current')
          );
        }
        //Hammad Tahir - goal help email redirection - 101021
        else if (target == 'goalHelp') {
          sessionStorage.setItem('UserId',userId!);
          await dispatch(fetchGoals());
          await dispatch(
              selectPerson(history, people.find(item => item.UserId === userId)!, `/goals/current/${sessionStorage.getItem('goalId')}`)
          );
        }
        else if (target == 'journal') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/journey/all')
          );
        }
        else if (target == 'story') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/story')
          );
        }
        else if (target == 'safety') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/safety')
          );
        }
        else if (target == 'network') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/network/people')
          );
        }
        else {
          history.push('/home');
        }
      }
    }
    else {
      history.push('/home');
    }
  } catch (err) {
    // console.log('catch: ', err.toString());
    history.push('/home');
    // dispatch(failed(err.toString()));
  }
};

export const endSession = (): AppThunk => async dispatch => {
  try {
    authentication.signOut();
    sessionStorage.clear();
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */
export const callProfileReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/Profile/Read/?contactType=935000001').then(response => {
    const profile: Profile = response.data;
    sessionStorage.setItem('Provider_UserId', profile.UserId);
    sessionStorage.setItem('Provider_SafetyPlanId', profile.SafetyPlanId);
    sessionStorage.setItem('Provider_FirstName', profile.FirstName);
    sessionStorage.setItem('Provider_LastName', profile.Surname);
    sessionStorage.setItem(
      'Provider_Avatar',
      profile.Image
        ? 'data:image/png;base64,' + profile.Image
        : '/images/avatar/provider_avatar.png'
    );
    sessionStorage.setItem('Provider_Email', profile.PrimaryEmail);
    sessionStorage.setItem('Provider_ContactId', profile.ContactId);
    sessionStorage.setItem('Provider_RecoveryPlanId', profile.RecoveryPlanId);
    sessionStorage.setItem('Provider_MobilePhone', profile.MobilePhone);
    sessionStorage.setItem('LoggedInAs',profile.ContactType) //Hammad Tahir - switchAccountFix - 190721
    return profile;
  });
};

export const callProfileSetUpApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post(`/Profile/Setup/935000001/${sessionStorage.getItem('InvitationId')}`); //Hammad Tahir - invitationFix - 101021 - Send inviation Id if profile setup from invitation
};

export const callProfileCheckApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post('/Profile/Check', { name: "check" }, { timeout: 10000 });//Jorge Avendano T43.3 270720 Redirects to the index page in case of any issue
};

export const callRecoveryPlanListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.get('/RecoveryPlan/List').then(response => {
    const people: Person[] = JSON.parse(JSON.stringify(response.data));
    return people;
  });
};
