import React, { useState, useEffect, ChangeEvent } from 'react';
import validate from 'validate.js';

import { makeStyles } from '@material-ui/styles';
import { useDispatch, useStore } from 'react-redux';
import { Grid, IconButton, TextField } from '@material-ui/core';
import { Close, Email } from '@material-ui/icons';

import { Button } from 'common/components';
import InviteConfirmation from '../../../common/components/InviteConfirmation'
import { Invitation } from 'types/network';
import { sendInvitation } from 'slices/invitation/action';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    height: '100%'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '35px',
    color: '#C57D7D'
  },
  desc: {
    fontFamily: 'Thasadith',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '127.69%',
    color: '#323F45'
  },
  closeText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#003E1F',
    cursor: 'pointer'
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px'
  },
  confirmTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#C57D7D'
  }
}));

const schema = {
  EmailAddress: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

type FormStateType = {
  isValid: boolean;
  values: {
    EmailAddress?: string;
  };
  touched: {
    EmailAddress?: boolean;
  };
  errors: {
    EmailAddress?: string[];
  };
};

type Props = {
  close: () => void;
};

function Alert(props: AlertProps) {
  // Prafful Jagtap D06 - If user already exists
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InvitePeople: React.FC<Props> = ({ close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Prafful Jagtap - Issue D06 - Display error message on fail API
  const store = useStore();
  // This is to show the error message as a Snackbar.
  const [snack, snackOpen] = React.useState({
    status: false,
    message: '',
    showError: false,
    severity: 'info' as AlertProps['severity']
  });

  const handleClick = (message: string, severity: string) => {
    snackOpen({
      showError: true,
      status: true,
      message: message,
      severity: severity as AlertProps['severity']
    });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    snackOpen({
      ...snack,
      status: false,
      message: ''
    });
  };

  const [formState, setFormState] = useState<FormStateType>({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [invitation, setInvitation] = useState<Invitation>({
    InvitationId: '',
    Name: sessionStorage.getItem('Provider_FirstName')!,
    EmailAddress: '',
    Subject: 'Invitation',
    Message: '',
    UserId: '',
    AcceptedOn: '',
    AccountType: 'Consumer',
    Relationship: '',
    SharingPurpose: '',
    InvitationCode: '',
    CreatedOn: ''
  });

  /** Send invitation */
  const submitSendInvitation = async () => {
    handleClick('Inviting...', 'info');
    //Hammad Tahir 131021 - [SP] If attempting to invite an already connected user
    //commented old code below
    // dispatch(sendInvitation(invitation));
    // setTimeout(() => {
    //   // Prafful Jagtap - Issue J14 - Display error message on fail API
    //   if (store.getState().people.error) {
    //     handleClick(
    //       invitation.EmailAddress + ' is already in your Network!',
    //       'error'
    //     );
    //   } else {
    //     close();
    //   }
    // }, 1500);
    //Hammad Tahir 131021 - [SP] If attempting to invite an already connected user
    //added new code below
    await dispatch(sendInvitation(invitation));
    setTimeout(() => {
      // Prafful Jagtap - Issue J14 - Display error message on fail API
      if (store.getState().people.error) {
        handleClick(
          invitation.EmailAddress + ' is already in your Network!',
          'error'
        );
      } else {
        close();
      }
    }, 1500);
  };

  const handleInvitationFields = (name: string, value: string | boolean) => {
    setInvitation(values => ({
      ...values,
      [name]: value
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (snack.showError) {
      // Prafful Jagtap - Issue D06 - Display error message on fail API
      snackOpen({
        ...snack,
        showError: false
      });
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));

    handleInvitationFields(event.target.name, event.target.value);
  };

  const hasError = (field: string): boolean =>
    field in formState.touched && field in formState.errors ? true : false;

  /** Dialog */
  const [open, setOpen] = useState(false);

  function openDialogHandler() {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        EmailAddress: invitation.EmailAddress
      },
      touched: {
        ...formState.touched,
        EmailAddress: true
      }
    }));

    if (formState.isValid) {
      setOpen(true);
    }
  }

  const confirmDialog = (
    <InviteConfirmation
      open={open}
      close={() => setOpen(false)}
      action={submitSendInvitation}
    >
      <span className={classes.confirmTitle}>
        Invite this person?
      </span>
    </InviteConfirmation>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snack.status}
          autoHideDuration={10000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={snack.severity}>
            {snack.message}
          </Alert>
        </Snackbar>
        <Grid item xs={12}>
          <IconButton className={classes.closeIcon} onClick={close}>
            <Close fontSize="large" style={{ fill: '#C57D7D' }} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.title}>Invite client</span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.desc}>
            Invite clients who's under your care by sending invitation to their
            email.
          </span>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <TextField
            error={hasError('EmailAddress') || snack.showError}
            fullWidth
            label="Enter email address"
            name="EmailAddress"
            autoComplete="off"
            variant="outlined"
            value={formState.values.EmailAddress || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="primary" click={openDialogHandler}>
            <div className={classes.buttonContent}>
              <Email style={{ fill: '#FFFFFF', marginRight: '5px' }} />
              Send email invitation
            </div>
          </Button>
        </Grid>
        <Grid item xs={12} container justify="center">
          <span className={classes.closeText} onClick={close}>
            Close
          </span>
        </Grid>
      </Grid>
      {open && confirmDialog}
    </div>
  );
};

export default InvitePeople;
