import React, { Suspense } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { makeStyles, useTheme } from '@material-ui/styles';
import {
  LinearProgress,
  Hidden,
  Dialog,
  DialogContent,
  useMediaQuery,
  Theme
} from '@material-ui/core';

import { NavBar, TopBar } from '../components';
import { LogoutPopup } from 'common/components';

import { useState } from 'react'; //Hammad Tahir - Responsive Layout - 090621 - addition
import useRouter from 'common/utils/useRouter'; // Hammad Tahir - Responsive Layout/About View - 170621 - added useRouter to get history
import About from 'views/About'; // Hammad Tahir - Responsive Layout/About View - 170621 - imported About component to be directly rendered on about route
import { callProfileReadApi } from 'slices/auth/action';
import { useEffect } from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import { css } from '@emotion/core';

//Hammad Tahir - switchAccountFix - 190721 - override for climberbox
const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  mainContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    height: '64px',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.12)'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 200,
    minWidth: 150,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: '#FFFAEA'
  },
  //Hammad Tahir - switchAccountFix - 190721 - climberbox loading container
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFEAEA'
  }
}));

const MainLayout: React.FC<RouteConfigComponentProps> = ({ route }) => {
  const classes = useStyles();
  const { history } = useRouter(); // Hammad Tahir - Responsive Layout/About View - 170621 - get history

  //Hammad Tahir - switchAccountFix - 190721 - Start
  /** Theme */
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('xs'));

  const [profileRead, setProfileRead] = useState(false);

  const loginCheck = async () => {
    if (sessionStorage.getItem('LoggedInAs') === null) {
      await callProfileReadApi().then(() => setProfileRead(true));
    }
    if (sessionStorage.getItem('LoggedInAs') !== 'Practitioner') {
      history.replace('/errors/error-403');
    }
  };
  //Hammad Tahir - switchAccountFix - 190721 - End

  //Hammad Tahir - Responsive Layout - 090621 - Adding menu button for Nav bar Begin
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };
  //Hammad Tahir - Responsive Layout - 090621 - end

  const [active, setActive] = useState(0); // Hammad Tahir - Responsive Layout/About View - 170621 - added active state to pass as props for the about view

  //Hammad Tahir - switchAccountFix - 190721 - Start
  useEffect(() => {
    loginCheck();
  }, [profileRead]);
  //Hammad Tahir - switchAccountFix - 190721 - End

  return (
    <>
      {/* Hammad Tahir - switchAccountFix - 190721 - Show climber box while reading profile */}
      {sessionStorage.getItem('LoggedInAs') === null ? (
        <main className={classes.content}>
          <Dialog open keepMounted fullScreen={fullScreen}>
            <DialogContent className={classes.loadingContainer}>
              <ClimbingBoxLoader
                css={override}
                size={20}
                color={'#692B40'}
                loading
              />
            </DialogContent>
          </Dialog>
        </main>
      ) : (
        <div className={classes.root}>
          <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen} // Hammad Tahir - Responsive Layout - 090621 - property added
            setActive={setActive} // Hammad Tahir - Responsive Layout/About View - 170621 - props added to display relevant about page
            active={active}
          />
          <div className={classes.mainContainer}>
            {/* <Hidden mdDown> */}{' '}
            {/* Hammad Tahir - Responsive Layout - 090621 -removed Hidden mdDown element */}
            <NavBar
              className={classes.navBar}
              //Hammad Tahir - Responsive Layout - 090621 - following properties added
              onMobileClose={handleNavBarMobileClose}
              openMobile={openNavBarMobile}
            />
            {/* </Hidden> */}
            <div className={classes.container}>
              <main className={classes.content}>
                {history.location.pathname === '/about' ? (
                  <About active={active} setActive={setActive} /> // Hammad Tahir - Responsive Layout/About View - 170621 - Display about view if path is about
                ) : (
                  <Suspense fallback={<LinearProgress />}>
                    {route && renderRoutes(route.routes)}
                  </Suspense>
                )}
              </main>
            </div>
          </div>
        </div>
      )}
      <LogoutPopup />
    </>
  );
};

export default MainLayout;
