import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import useRouter from 'common/utils/useRouter';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Grid,
  Dialog,
  DialogContent,
  Avatar,
  Slide,
  Theme,
  useMediaQuery,
  Hidden,
  IconButton //Hammad Tahir - Responsive Layout - 090621 - addition
} from '@material-ui/core';
import { Menu } from '@material-ui/icons'; //Hammad Tahir - Responsive Layout - 090621 - addition
import CloseIcon from '@material-ui/icons/Close'; //Hammad Tahir - Responsive Layout/About View - 170621 - added close icon to be displayed on top bar for about view

import ProfileDialog from '../ProfileDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from 'slices/profile/action';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { EnterCode, InvitePeople, AddPeople } from 'common/components';
// Jorge Avendano T29 070820 get notifications begin
import Badge from '@material-ui/core/Badge';
import { getUnreadNotificationApi } from 'slices/notifications/action';
import { RootState } from 'reducer';
// Jorge Avendano T29 070820 end

//Jorge Avendano T8 260820 Implementation of push notifications through the browser begin
import { NotificationItem } from 'types/notification';
import addNotification from 'react-push-notification';
//Jorge Avendano T8 end

import { Profile } from 'types/profile'; //Hammad Tahir - fetchProfileFix - 190721

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#692B40'
  },
  /** Search Input */
  input: {
    marginLeft: '8px',
    '& .MuiInputBase-input': {
      color: '#FFFFFF'
    }
  },
  iconButton: {
    padding: 10
  },
  /** Menu */
  // Hammad Tahir - Responsive Layout/About View - 170621 - added topMenuContainer
  topMenuContainer: {
    display: 'flex',
    minWidth: 0,
    alignItems: 'center',
    paddingTop: '1%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  // Hammad Tahir - Responsive Layout/About View - 170621 - removed margin right and added padding and breakpoint in topMenu
  topMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: '0',
    padding: '2%',
    paddingTop: '1%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
      padding: '2%'
    }
  },
  topMenuText: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '0.8rem',
    color: '#FFFFFF',
    whiteSpace: 'nowrap', //Hammad Tahir - Responsive Layout - 090621 - addition
    // Hammad Tahir - Responsive Layout/About View - 170621 - added breakpoint
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem'
    }
  },
  icon: {
    fontSize: '30px'
  },
  menuIcon: {
    width: '25px',
    height: '18px',
    marginBottom: '2px'
  },
  /** Profile Dialog */
  navProfile: {
    zIndex: 3,
    top: '64px',
    right: '0',
    position: 'fixed',
    background: '#FFFFFF',
    borderRadius: '6px',
    width: '240px',
    padding: '15px',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.12)'
  },
  // Hammad Tahir - Responsive Layout/About View - 170621 - changed bottom margin and added breakpoint in avatar
  avatar: {
    cursor: 'pointer',
    marginBottom: '7px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  enterCode: {
    [theme.breakpoints.up('xs')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
      width: '400px',
      height: '240px',
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    }
  },
  invitePeople: {
    [theme.breakpoints.up('xs')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      padding: '0'
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      padding: '0'
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      height: '600px',
      position: 'relative',
      background: '#FFFFFF',
      padding: '0'
    }
  },
  addPeople: {
    [theme.breakpoints.up('xs')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px',
      width: '400px',
      height: '290px',
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 30px',
      width: '400px',
      height: '290px',
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    }
  },
  logo: {
    cursor: 'pointer',
    width: 131,
    height: 64
  },
  customBadge: {
    // Jorge Avendano T29 070820 dot style
    backgroundColor: '#FFD233',
    padding: '4px',
    paddingBottom: '4px',
    fontSize: '16px',
    margin: '-5px'
  }
}));

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

type Props = {
  className: string;
  //Hammad Tahir - Responsive Layout - 090621 - added onOpenNavBarMobile funtion as prop
  onOpenNavBarMobile: () => void;
  // Hammad Tahir - Responsive Layout/About View - 170621 - added following props
  active: number;
  setActive: (num: number) => void;
};

const TopBar: React.FC<Props> = ({
  className,
  //Hammad Tahir - Responsive Layout - 090621 - added onOpenNavBarMobile
  onOpenNavBarMobile,
  // Hammad Tahir - Responsive Layout/About View - 170621 - added setActive and active to display the about pages
  setActive,
  active
}) => {
  const { history } = useRouter();
  const dispatch = useDispatch();

  const classes = useStyles();

  //Hammad Tahir - fetchProfileFix - 190721 - Get profile from redux store
  const profile: Profile = useSelector(
    (state: RootState) => state.profile.profile!
  );

  const handleClickOnLogo = () => {
    sessionStorage.setItem('UserId', '');
    sessionStorage.setItem('FirstName', '');
    sessionStorage.setItem('SurName', '');
    sessionStorage.setItem('Photo', '');
    sessionStorage.setItem('MobilePhone', '');
    sessionStorage.setItem('PrimaryEmail', '');
    history.push('/home');
  };

  const handleMenuClick = (menu: string) => {
    if (menu === 'settings') {
      history.push('/settings');
    } else if (menu === 'notifications') {
      history.push('/notifications');
    }
  };

  useEffect(() => {
    Object.keys(profile).length === 0 && dispatch(fetchProfile()); //Hammad Tahir - fetchProfileFix - 190721 - Only fetch if profile is not loaded
  }, [dispatch]);

  /** Provile Dialog */
  const [open, setOpen] = useState(false);
  // Prafful Jagtap   Task D03 - Display success message when contact is added and close dialog box
  const onProfileChange = () => {
    setOpen(false);
  };
  const profileDialog = (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="form-dialog-title">
      <DialogContent className={classes.navProfile}>
        <ProfileDialog onProfileChange={onProfileChange} />{' '}
        {/* Prafful Jagtap   Task D03 - Display success message when contact is added and close dialog box */}
        {/* <ProfileDialog /> */}
      </DialogContent>
    </Dialog>
  );

  const [open2, setOpen2] = useState(false);
  const enterCodeDialog = (
    <Dialog
      open={open2}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen2(false)}>
      <DialogContent className={classes.enterCode}>
        <EnterCode close={() => setOpen2(false)} />
      </DialogContent>
    </Dialog>
  );

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open3, setOpen3] = useState(false);
  const invitePeopleDialog = (
    <Dialog
      open={open3}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      keepMounted
      onClose={() => setOpen3(false)}>
      <DialogContent className={classes.invitePeople}>
        <InvitePeople close={() => setOpen3(false)} />
      </DialogContent>
    </Dialog>
  );

  const [open4, setOpen4] = useState(false);
  const addPeopleDialog = (
    <Dialog
      open={open4}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen4(false)}>
      <DialogContent className={classes.addPeople}>
        <AddPeople
          close={() => setOpen4(false)}
          openEnterCode={() => setOpen2(true)}
          openInvitePeople={() => setOpen3(true)}
        />
      </DialogContent>
    </Dialog>
  );

  // Jorge Avendano T29 070820 Get unread notifications and show dot begin
  // const count: string = useSelector(
  //   (state: RootState) => state.notifications.count
  // );

  const unReadNotifications: NotificationItem[] = useSelector(
    (state: RootState) => state.notifications.unReadNotifications
  );

  let hideDot = true;

  sessionStorage.setItem('notifSession', unReadNotifications.length.toString());
  hideDot = sessionStorage.getItem('notifSession') !== '0' ? false : true;

  useEffect(() => {
    dispatch(getUnreadNotificationApi());
  }, [dispatch]);
  // Jorge Avendano T29 070820 end

  //Jorge Avendano T8 260820 Implementation of push notifications through the browser begin

  let notificationTitle = '';
  let notificationIcon = '';
  let shownNotifications = '';
  if (sessionStorage.getItem('shownNotifications') != null)
    shownNotifications = JSON.parse(
      sessionStorage.getItem('shownNotifications')!
    );

  if (unReadNotifications.length > 0) {
    for (let item of unReadNotifications) {
      if (!shownNotifications.includes(item.Id)) {
        if (item.NotificationType === 'Comment') {
          notificationTitle = 'You have a new comment';
          notificationIcon =
            'https://recoveryplanstorage.blob.core.windows.net/assets/Comment.png';
        }
        if (item.NotificationType === 'Suggestion') {
          notificationTitle = 'You have a new suggestion';
          notificationIcon =
            'https://recoveryplanstorage.blob.core.windows.net/assets/Suggestions.png';
        }
        if (item.NotificationType === 'AccessRequest') {
          notificationTitle = 'Access request';
          notificationIcon =
            'https://recoveryplanstorage.blob.core.windows.net/assets/Request.png';
        }
        if (item.NotificationType === 'System') {
          notificationTitle = 'Goal reminder';
          notificationIcon =
            'https://recoveryplanstorage.blob.core.windows.net/assets/Reminder.png';
        }
        if (item.NotificationType === 'Update') {
          notificationTitle = '';
          notificationIcon =
            'https://recoveryplanstorage.blob.core.windows.net/assets/Update.png';
        }
        addNotification({
          title: notificationTitle,
          message: item.Message.replace('<strong>', '').replace(
            '</strong>',
            ''
          ),
          duration: 10000,
          icon: notificationIcon,
          //theme: 'darkblue',
          native: true // when using native, your OS will handle theming.
        });
        notificationTitle = '';
        notificationIcon = '';
        shownNotifications = shownNotifications + ',' + item.Id;
      }
    }
    sessionStorage.setItem(
      'shownNotifications',
      JSON.stringify(shownNotifications)
    );
  }
  //Jorge Avendano T8 260820 end

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={2}>
            {/* Hammad Tahir - Responsive Layout - 090621 - addition */}
            <div style={{ flexGrow: 1 }}>
              <Hidden mdDown>
                <img
                  src="/images/landing/logo.svg"
                  alt=""
                  className={classes.logo}
                  onClick={handleClickOnLogo}
                />
              </Hidden>
              <Hidden lgUp>
                {/* Hammad Tahir - Responsive Layout - 090621 - added Menu button
                Hammad Tahir - Responsive Layout/About View - 170621 - added condition and Close button to be displayed on topBar when any about view is loaded
            */}
                <IconButton color="inherit">
                  {active === 0 ? (
                    <Menu
                      onClick={onOpenNavBarMobile}
                      className={classes.icon}
                    />
                  ) : (
                    <CloseIcon
                      className={classes.icon}
                      onClick={() => setActive(0)}
                    />
                  )}
                </IconButton>
              </Hidden>
            </div>
          </Grid>
          {/* <Grid item xs={5}>  */}
          {/* <InputBase
              className={classes.input}
              placeholder="Search consumer"
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search">
              <Search style={{ fill: '#FFEAEA' }} />
            </IconButton> */}
          {/* </Grid> */}
          <Grid item xs={10}>
            {/* Hammad Tahir - Responsive Layout/About View - 170621 - moved div styling to topMenuContainer class */}
            <div className={classes.topMenuContainer}>
              <div className={classes.topMenu} onClick={handleClickOnLogo}>
                <img
                  src="/images/topbar/consumers.svg"
                  alt=""
                  className={classes.menuIcon}
                />
                <span className={classes.topMenuText}>My Clients</span>
              </div>
              {/* <div className={classes.topMenu}>
                  <img
                    src="/images/topbar/invites.svg"
                    alt=""
                    className={classes.menuIcon}
                  />
                  <span className={classes.topMenuText}>Invites</span>
                </div> */}
              <div
                className={classes.topMenu}
                onClick={() => handleMenuClick('notifications')}>
                {/* Jorge Avendano T29 070820 add notification dot*/}
                <Badge
                  badgeContent={sessionStorage.getItem('notifSession')}
                  invisible={hideDot}
                  color="error"
                  overlap="circle"
                  classes={{ badge: classes.customBadge }}>
                  <img
                    src="/images/topbar/notif.svg"
                    alt=""
                    className={classes.menuIcon}
                  />
                </Badge>
                <span className={classes.topMenuText}>Notifications</span>
              </div>
              <div
                className={classes.topMenu}
                onClick={() => handleMenuClick('settings')}>
                <img
                  src="/images/topbar/settings.svg"
                  alt=""
                  className={classes.menuIcon}
                />
                <span className={classes.topMenuText}>Settings</span>
              </div>
              <Avatar
                alt=""
                className={classes.avatar}
                src={sessionStorage.getItem('Provider_Avatar')!}
                onClick={() => setOpen(true)}
              />
            </div>
          </Grid>
        </Grid>
      </Toolbar>
      {open && profileDialog}
      {open2 && enterCodeDialog}
      {open3 && invitePeopleDialog}
      {open4 && addPeopleDialog}
    </AppBar>
  );
};

export default TopBar;
