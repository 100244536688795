import axios from 'axios';

let webApiUrl = '';
if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
  webApiUrl = 'https://jiembawebapi.azurewebsites.net/api';
}
else if (process.env.REACT_APP_ENVIRONMENT === 'UAT') {
  webApiUrl = 'https://recoveryplanwebapi.azurewebsites.net/api'
}
else {
  webApiUrl = 'https://recoveryplanwebapitest.azurewebsites.net/api'
}

const instance = axios.create({

  baseURL: webApiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    ContactType: '935000001'
  }
});

export default instance;
