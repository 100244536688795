import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import authentication from '@kdpw/msal-b2c-react';

import store from './store';
import { Provider as StoreProvider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
  authentication.initialize({
    // you can user your b2clogin.com domain here, setting is optional, will default to this
    //instance: 'https://AIAustraliaB2C.b2clogin.com/tfp/',
    // instance: 'https://jiembab2c.b2clogin.com/',
    //Hammad Tahir - T531 - 161121 - Remove jiemba from azure b2c sign in/sign up page url
    instance: 'https://login.journi.org.au/',
    // your B2C tenant, you can also user tenants GUID here
    // tenant: 'jiembab2c.onmicrosoft.com',
    //Hammad Tahir - T531 - 161121 - Remove jiemba from azure b2c sign in/sign up page url
    tenant: '687f27c9-83ed-492a-973d-30bcf925567c',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1_SignUpAndSignIn_SP',
    // the policy to use for password reset
    resetPolicy: 'B2C_1_PasswordReset_SP',
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    applicationId: '8b36fc64-5c89-4bea-bb04-57dd4f7137d7',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'sessionStorage',
    // the scopes you want included in the access token
    // scopes: ['https://jiembab2c.onmicrosoft.com/api/user_impersonation'],
    //Hammad Tahir - T531 - 161121 - Remove jiemba from azure b2c sign in/sign up page url
    scopes: ['https://login.journi.org.au/api/user_impersonation'],
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    //Hammad Tahir - productionUrlChange - 101021
    redirectUri: 'https://marlee.journi.org.au',
    // optional, the URI to redirect to after logout
    //Hammad Tahir - productionUrlChange - 101021
    postLogoutRedirectUri: 'https://marlee.journi.org.au',
    // optional, default to true, set to false if you change instance
    validateAuthority: false,
    // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
    silentLoginOnly: false
  });
}
else if (process.env.REACT_APP_ENVIRONMENT === 'UAT' ||
  process.env.REACT_APP_ENVIRONMENT === 'TEST' ||
  process.env.REACT_APP_ENVIRONMENT === 'DEV') {
  authentication.initialize({
    // you can user your b2clogin.com domain here, setting is optional, will default to this
    instance: 'https://AIAustraliaB2C.b2clogin.com/tfp/',
    // your B2C tenant, you can also user tenants GUID here
    tenant: 'AIAustraliaB2C.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1_SignUpAndSignIn_SP',
    // the policy to use for password reset
    resetPolicy: 'B2C_1_PasswordReset_SP',
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    applicationId: 'a6cbdbad-8f34-46a2-bcf9-f2c865e354ce',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'sessionStorage',
    // the scopes you want included in the access token
    scopes: ['https://AIAustraliaB2C.onmicrosoft.com/api/user_impersonation'],
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    //redirectUri: 'https://drmarleedev.azurewebsites.net',
    redirectUri: process.env.NODE_ENV === 'development' ?
      'http://localhost:3000' :
      'https://drmarleedev.azurewebsites.net',
    // optional, the URI to redirect to after logout
    //postLogoutRedirectUri: 'https://drmarleedev.azurewebsites.net',
    postLogoutRedirectUri: process.env.NODE_ENV === 'development' ?
      'http://localhost:3000' :
      'https://drmarleedev.azurewebsites.net',
    // optional, default to true, set to false if you change instance
    validateAuthority: false,
    // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
    silentLoginOnly: false
  });
}

const render = () => {
  const App = require('./App').default;

  ReactDOM.render(
    <StoreProvider store={store}>
      <App />
    </StoreProvider>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}

serviceWorker.register();
