import React from 'react';
import clsx from 'clsx';
import useRouter from 'common/utils/useRouter';

import { makeStyles } from '@material-ui/styles'; 
import { Paper, Theme, Divider, Avatar, Slide, Hidden, Drawer } from '@material-ui/core'; //Hammad Tahir - Responsive Layout - 090621 - added  Slide, Hidden and Drawer

import { Navigation } from 'common/components';
import navigationConfig from '../../navigationConfig';
import moment from 'moment';
import { TransitionProps } from '@material-ui/core/transitions'; //Hammad Tahir - Responsive Layout - 090621 - addition

import { useEffect } from 'react'; //Hammad Tahir - Responsive Layout - 090621 - modified

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    background: '#FFFFFF'
  },
  content: {
    padding: theme.spacing(1)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  divider: {
    border: '1px solid #E0E0E0'
  },
  /** Profile */
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 40,
    height: 40
  },
  profileName: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '10px',
    width: '150px',
    wordWrap: 'break-word',
    textAlign: 'center'
  },
  profileDate: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
    color: '#000000',
    marginTop: '-2px'
  },
  profileButton: {
    width: '142px',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    color: '#FFFFFF',
    background: '#692B40',
    cursor: 'pointer',
    margin: '10px 0 20px',
    padding: '7px 0',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      backgroundColor: '#692B40'
    },
    '&:active': {
      backgroundColor: '#692B40'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.15px',
    color: '#FFFFFF',
    textTransform: 'uppercase'
  },
  /** Contact */
  contact: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px'
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0'
  },
  contactText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
    color: '#C57D7D',
    width: '120px',
    wordWrap: 'break-word'
  }
}));

//Hammad Tahir - Responsive Layout - 090621 - added Transistion value
const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => <Slide direction="right" ref={ref} {...props} />
);

type Props = {
  className: string;
  //Hammad Tahir - Responsive Layout - 090621 - added following props
  onMobileClose: () => void;
  openMobile: boolean;
};

const NavBar: React.FC<Props> = ({ 
  className,
  //Hammad Tahir - Responsive Layout - 090621 - added following props
  openMobile,
  onMobileClose,
  ...rest
 }) => {
  const classes = useStyles();
  const { history } = useRouter();

  ////Hammad Tahir - Responsive Layout - 090621 - added router
  const router = useRouter();

  ////Hammad Tahir - Responsive Layout - 090621 - added following use effect
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);



  const handleProfileButtonClick = () => {
    if (
      sessionStorage.getItem('UserId') !== null &&
      sessionStorage.getItem('UserId') !== ''
    ) {
      history.push('/consumer');
    }
  };

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        <div className={classes.profile}>
          {sessionStorage.getItem('UserId') ? (
            <Avatar
              alt=""
              className={classes.avatar}
              src={sessionStorage.getItem('Photo')!}
            />
          ) : (
              <Avatar alt="" className={classes.avatar} src="" />
            )}
          <span className={classes.profileName}>
            {sessionStorage.getItem('FirstName')}
          </span>
          {/* <span className={classes.profileName}>
            {sessionStorage.getItem('SurName')}
          </span> */}
          {sessionStorage.getItem('DateOfBirth') && (
            <span className={classes.profileDate}>
              {moment(sessionStorage.getItem('DateOfBirth')!).format(
                'DD/MM/YYYY'
              )}
            </span>
          )}
          <button
            className={classes.profileButton}
            onClick={handleProfileButtonClick}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <img
                src="/images/consumer/profile_icon.svg"
                alt=""
                style={{ marginRight: '5px' }}
              />
              <span className={classes.buttonText}>Profile</span>
            </div>
          </button>
        </div>
        <Divider className={classes.divider} />
        {navigationConfig.map(list => (
          <Navigation key={list.title} pages={list.pages} title={list.title} />
        ))}
        <div className={classes.contact}>
          <div className={classes.contactItem}>
            <img
              src="/images/navbar/call.svg"
              alt=""
              style={{ marginRight: '15px' }}
            />
            <div className={classes.contactText}>
              {sessionStorage.getItem('MobilePhone')}
            </div>
          </div>
          <div className={classes.contactItem}>
            <img
              src="/images/navbar/email.svg"
              alt=""
              style={{ marginRight: '15px' }}
            />
            <div className={classes.contactText}>
              {sessionStorage.getItem('PrimaryEmail')}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );

  return (
    <>
    {/* Hammad Tahir - Responsive Layout - 090621 - Added Hidden lgUp and all child elements */}
    <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      {/* Hammad Tahir - Responsive Layout - 090621 - added hidden mddown tag */}
    <Hidden mdDown>
      <Paper 
      {...rest} //Hammad Tahir - Responsive Layout - 090621 - added rest property
      className={clsx(classes.root, className)}
      elevation={1}
      square>
      {navbarContent}
      </Paper>
      </Hidden>
    </>
  );
};

export default NavBar;
