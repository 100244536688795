import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import {
  startLoading,
  stopLoading,
  fetchNetworks,
  fetchMyContacts
} from './networkSlice';
import { Network } from 'types/network';
import uuid from 'uuid';

//** ASYNC FUNCS */
export const fetchEmergencyNetworks = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());

    const networks = await callNetworkContactCarerReadApi();

    dispatch(
      fetchNetworks({
        networks
      })
    );

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const addNetwork = (
  name: string,
  phone: string
): AppThunk => async dispatch => {
  try {
    await callNetworkContactCreateApi(name, phone);
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const deleteNetwork = (id: string): AppThunk => async dispatch => {
  try {
    await callNetworkContactDeleteApi(id);
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const fetchProviderContacts = (): AppThunk => async dispatch => {
  try {
    const contacts = await callNetworkContactListApi();
    dispatch(fetchMyContacts({ contacts }));
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const sendNetworkAccessRequest = (): AppThunk => async dispatch => {
  try {
    await callNetworkCarerRequestAccessApi();
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */

export const callNetworkContactCarerReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios
    .get(`/NetworkContact/Carer/Read/${sessionStorage.getItem('UserId')!}`)
    .then(response => {
      const emergencyContacts: Network[] = JSON.parse(
        JSON.stringify(response.data)
      );
      //Jorge Avendano 230920 D14 set profile picture begin
      const updatedNetworks: Network[] = emergencyContacts.map(item => {
        return {
          ...item,
          Type: item.Type ? item.Type : 'Person',
          Name: item.Name ? item.Name : '',
          Phone: item.Phone ? item.Phone : '',
          ImageType: item.ImageType ? item.ImageType : '',
          ImageUrl: item.ImageUrl ? item.ImageUrl : '',
          Image: item.Image
            ? 'data:image/png;base64,' + item.Image
            : item.Type === 'Person'
              ? '/images/avatar/carerDefaultAvatar.svg'
              : '/images/avatar/provider_avatar.png',
          Access: item.Access === '' ? 'No Access' : item.Access?.slice(0,-2), //Hammad Tahir T278 031021 - "Can access" field on network contact when viewed on SP
          Relationship: item.Relationship ? item.Relationship : '-', //Hammad Tahir T278 031021 - "Can access" field on network contact when viewed on SP
          Organization: item.Organization ? item.Organization : '-' //Hammad Tahir T278 031021 - "Can access" field on network contact when viewed on SP
        };
      });
      //Jorge Avendano 230920 D14 end
      return updatedNetworks;
    });
};

const callNetworkContactCreateApi = (name: string, phone: string) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  // Caroline Bezzina 25/09/2020 Task J28.2 - No name for accepted suggested contacts in Safety Plan and Network on SP side
  const network: Network = {
    Id: uuid(),
    UserId: sessionStorage.getItem('Provider_UserId')!,
    ContactId: uuid(),
    Name: name,
    ContactName: '',
    Email: '',
    Phone: phone,
    CallForSupport: false,
    Address: '',
    Type: '',
    Relationship: '',
    Image: '',
    ImageType: '',
    ImageUrl: ''
  };

  return axios.post('/NetworkContact/Create', network);
};

const callNetworkContactListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/NetworkContact/List').then(response => {
    const networks: Network[] = JSON.parse(JSON.stringify(response.data));
    return networks;
  });
};

const callNetworkContactDeleteApi = (id: string) => {
  return axios.delete(`/NetworkContact/Delete/${id}`);
};

const callNetworkCarerRequestAccessApi = () => {
  return axios.post(
    `/NetworkContact/Carer/RequestAccess/${sessionStorage.getItem(
      'RecoveryPlanId'
    )}`
  );
};
