import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import {
  fetch,
  startLoading,
  stopLoading,
  hideError,
  showError, 
  setErrorMsg //Hammad Tahir - create client profile show error - 101021
} from './peopleSlice';
import { Person } from 'types/people';
import { callRecoveryPlanGetRecoveryUpdateApi } from 'slices/dashboard/action';
import { Profile, AddConsumer } from 'types/profile';
import { Invitation } from 'types/network';
import { sendInvitation } from 'slices/invitation/action';

//** ASYNC FUNCS */
export const fetchPeople = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const people = await callRecoveryPlanListApi();

    const updatedPeople: Person[] = [];
    for (const person of people) {
      const lastUpdate = await callRecoveryPlanGetRecoveryUpdateApi(
        person.RecoveryPlanId,
        person.LastRecPlanUpdate
      );
      const hasUpdate =
        lastUpdate.NewGoalCount > 0 ||
        lastUpdate.NewGoalStepCount > 0 ||
        lastUpdate.NewGoalStepCheckinCount > 0 ||
        lastUpdate.NewJournalEntryCount > 0;

      const updatedPerson: Person = {
        ...person,
        HasUpdate: hasUpdate
      };

      updatedPeople.push(updatedPerson);
    }

    dispatch(
      fetch({
        people: updatedPeople
      })
    );
    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const selectPerson = (
  history: any,
  person: Person,
  redirection: string //Jorge Avendano T32.3 310820 Notifications redirection
): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const consumerProfile = await callConsumerReadApi(person.RecoveryPlanId);

    sessionStorage.setItem('UserId', person.UserId);
    sessionStorage.setItem('FirstName', person.FirstName);
    sessionStorage.setItem('SurName', person.Surname);
    sessionStorage.setItem(
      'Photo',
      person.Photo
        ? 'data:image/png;base64,' + person.Photo
        : '/images/avatar/jiembaDefaultAvatar.svg'
    );
    sessionStorage.setItem('RecoveryPlanId', person.RecoveryPlanId);
    sessionStorage.setItem('LastRecPlanUpdate', person.LastRecPlanUpdate);
    sessionStorage.setItem('DateOfBirth', person.DateOfBirth);

    sessionStorage.setItem(
      'MobilePhone',
      consumerProfile.MobilePhone ? consumerProfile.MobilePhone : ''
    );
    sessionStorage.setItem(
      'PrimaryEmail',
      consumerProfile.PrimaryEmail ? consumerProfile.PrimaryEmail : ''
    );

    dispatch(stopLoading());
    //history.push('/dashboard');
    history.push(redirection); //Jorge Avendano T32.3 310820 Notifications redirection
  } catch (err) {
    // console.log('catch: ', err.toString());
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
    history.push('/home');
  }
};

export const addConsumer = (
  history: any,
  profile: AddConsumer,
  invitation: Invitation
): AppThunk => async dispatch => {
  try {
    dispatch(hideError()); // Prafful Jagtap - Issue D06 - Display error message on fail API
    dispatch(setErrorMsg({message: '' })) //Hammad Tahir - create client profile show error - 101021
    dispatch(startLoading());

    await callConsumerCreateApi(profile)
      .then(response => {
        dispatch(sendInvitation(invitation));
        history.push('/home');
      })
      .catch(err => {
        // Prafful Jagtap - Issue D06 - Display error message on fail API
        throw err;
      });

    dispatch(stopLoading());
  } catch (err) {
    dispatch(showError()); // Prafful Jagtap - Issue D06 - Display error message on fail API
    dispatch(setErrorMsg({message: err.response.data.Message })) //Hammad Tahir - create client profile show error - 101021
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - T535 - 240222 - Service provider can remove consumer from clients list
//Async fuction added to delete consumer
export const deleteConsumer = (
  id: string,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(startLoading());

    await callConsumerDeleteApi(id)
      .then(response => {
        dispatch(
          fetch({
            people: getState().people.people.filter(item => item.RecoveryPlanId !== id)
          })
        );
      })
      .catch(err => {
        throw err;
      });

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
  }
};

//** API FUNCS */
export const callRecoveryPlanListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.get('/RecoveryPlan/List').then(response => {
    const people: Person[] = JSON.parse(JSON.stringify(response.data));
    return people;
  });
};

const callConsumerCreateApi = (profile: AddConsumer) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post('/Consumer/Create', profile);
};

export const callConsumerReadApi = (recoveryPlanId: string) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.get(`/Consumer/Read/${recoveryPlanId}`).then(response => {
    const profile: Profile = JSON.parse(JSON.stringify(response.data));
    return profile;
  });
};

//Hammad Tahir - T535 - 240222 - Service provider can remove consumer from clients list
//API Function added to delete consumer
export const callConsumerDeleteApi = (recoveryPlanId: string) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.delete(`/NetworkContact/Delete/Consumer/${recoveryPlanId}`);
};