//Smarth Bajaj Changes
import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import { NavigateNext } from '@material-ui/icons';
import Iframe from 'react-iframe';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { ArrowBackIos } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2% 5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '7%',
      paddingRight: '7%'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%',
      paddingRight: '5%'
    },
    backgroundColor: '#FFFAEA'
  },
  textColor: {
    color: '#FFFFFF'
  },
  icon: {
    color: '#37474F',
    fontSize: '30px'
  },
  // contentRoot: {
  //   backgroundColor: '#FFFAEA',
  //   height: '100vh',
  //   width: '100vw',
  //   overflow: 'hidden'
  // },
  navBar: {
    backgroundColor: '#FFFFFF',
    minHeight: '64px',
    zIndex: 1
  },
  contentContainer: {
    marginTop: '3em',
    marginLeft: '8em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1em',
      marginRight: '1em',
      marginTop: '1em'
    }
    // display: 'flex',
    // justifyContent: 'flex-start'
  },
  displayContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '0.75em',
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px'
  },
  link: {
    textDecoration: 'none',
    color: '#C57D7D',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '127.69%'
  },
  textContainer: {
    fontFamily: 'Tajawal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '58px',
    color: '#37474F'
    // textAlign: 'center'
  },
  paperContainer: {
    // width: '30em',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1em',
      marginRight: '1em'
      // width: '28em'
    }
  },
  papertwoContainer: {
    width: '30em',
    [theme.breakpoints.down('xs')]: {
      width: '23em'
    }
  },
  itemContainer: {
    marginRight: 0,
    marginLeft: 0
  },
  jiembaHeading: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '37px',
    color: '#37474F'
  },
  jiembaSubHeading: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '127.69%',
    color: '#37474F',
    marginTop: '0.25em',
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: '3px solid #73BA9B',
    alignItems: 'center',
    width: '11em'
    //
  },
  jiembaTwoSubHeading: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '127.69%',
    color: '#37474F',
    marginTop: '0.5em',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '26em',
    [theme.breakpoints.down('xs')]: {
      width: '20em'
    }
  },
  topContainer: {
    marginTop: '1.5em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5em'
    }
  },
  //Smarth Bajaj - Task #24 - 02/Sept/2020
  frameContainer: {
    height: '100vh',
    width: '100vw'
  },
  iconContainer: {
    display: 'flex'
  }
}));

type Props = {
  // Hammad Tahir - Responsive Layout/About View - 170621 - props added
  setActive: (num: number) => void;
  active: number;
};
// Hammad Tahir - Responsive Layout/About View - 170621 - props passed as parameters
const About: React.FC<Props> = ({ setActive, active }) => {
  const classes = useStyles();
  const theme = useTheme();

  /** Dialog */
  // Hammad Tahir - Responsive Layout/About View - 170621 - active setActive removed
  // const [active, setActive] = useState(0);
  const matchesMD = useMediaQuery(theme.breakpoints.down('sm'));

  const url = 'https://journi.aihealth.tech/service-provider';

  const MainPage = (
    <Slide direction="right" mountOnEnter unmountOnExit in={active === 0}>
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Grid
              container
              // alignItems={matchesSM ? 'center' : 'flex-start'}
              direction="column">
              {/* <Grid item>
                <Hidden xsDown>
                  <Typography variant="h1">
                    <div className={classes.textContainer}>About</div>
                  </Typography>
                </Hidden>
              </Grid> */}
              <Grid
                item
                className={`${classes.itemContainer} ${classes.topContainer}`}>
                <Paper
                  className={classes.paperContainer}
                  style={{
                    padding: '0.15em',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                  }}>
                  <div className={classes.displayContainer}>
                    <img
                      style={{ marginRight: '0.75em' }}
                      src="/images/about/star.png"
                      alt="Use Jiemba"
                    />
                    <h4>How to use Journi?</h4>
                    <IconButton
                      onClick={() => setActive(1)}
                      style={{ marginLeft: 'auto' }}>
                      <NavigateNext
                        style={{ fill: '#C57D7D' }}
                        fontSize="default"
                      />
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className={classes.itemContainer}
                item
                style={{ marginTop: '1em' }}>
                <Paper
                  className={classes.paperContainer}
                  style={{
                    padding: '0.15em',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                  }}>
                  <div className={classes.displayContainer}>
                    <img
                      style={{ marginRight: '0.75em' }}
                      src="/images/about/questions.png"
                      alt="Use Jiemba"
                    />
                    <h4>Frequently asked questions</h4>
                    <IconButton
                      onClick={() => setActive(2)}
                      style={{ marginLeft: 'auto' }}>
                      <NavigateNext
                        style={{ fill: '#C57D7D' }}
                        fontSize="default"
                      />
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className={classes.itemContainer}
                item
                style={{ marginTop: '1em' }}>
                <Paper
                  className={classes.paperContainer}
                  style={{
                    padding: '0.15em',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                  }}>
                  <div className={classes.displayContainer}>
                    <img
                      style={{ marginRight: '0.75em' }}
                      src="/images/about/privacy.png"
                      alt="Use Jiemba"
                    />
                    <h4>Data privacy regulations</h4>
                    <IconButton
                      onClick={() => setActive(3)}
                      style={{ marginLeft: 'auto' }}>
                      <NavigateNext
                        style={{ fill: '#C57D7D' }}
                        fontSize="default"
                      />
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
              <Grid item style={{ marginTop: '3em' }}>
                <Grid container spacing={2} direction="column">
                  <Grid item className={classes.paperContainer}>
                    <a
                      className={classes.link}
                      href="mailto:support@journi.health">
                      Give feedback
                    </a>
                  </Grid>
                  <Grid
                    item
                    className={classes.paperContainer}
                    style={{ marginTop: '2em' }}>
                    <div className={classes.link}>Recommend to friends</div>
                  </Grid>
                  <Grid item className={classes.paperContainer}>
                    <div className={classes.iconContainer}>
                      <FacebookShareButton url={url}>
                        <FacebookIcon
                          style={{ fontSize: '40px', color: '#C57D7D' }}
                        />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        style={{ marginLeft: '1em' }}
                        url={url}>
                        <WhatsAppIcon
                          style={{ fontSize: '40px', color: '#C57D7D' }}
                        />
                      </WhatsappShareButton>
                      <LinkedinShareButton
                        style={{ marginLeft: '1em' }}
                        url={url}>
                        <LinkedInIcon
                          style={{ fontSize: '40px', color: '#C57D7D' }}
                        />
                      </LinkedinShareButton>
                      <TwitterShareButton
                        style={{ marginLeft: '1em' }}
                        url={url}>
                        <TwitterIcon
                          style={{ fontSize: '40px', color: '#C57D7D' }}
                        />
                      </TwitterShareButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Slide>
  );

  const useJiemba = (
    //Smarth Bajaj - Task #24 - 02/Sept/2020 - Added Back button
    // Hammad Tahir - Responsive Layout/About View - 170621 - Hiddedn component added to hide back arrow on breakpoint
    <>
      <div style={{ position: 'relative' }}>
        <Hidden mdDown>
          <IconButton
            style={{ position: 'absolute', top: 100, left: 100 }}
            onClick={() => setActive(0)}>
            <ArrowBackIos style={{ fill: '#692B40' }} fontSize="large" />
          </IconButton>
        </Hidden>
      </div>
      <Iframe
        url="https://journi.aihealth.tech/howtojourni/how-to-journi-providing-services/"
        className={classes.frameContainer}
        allowFullScreen={true}
      />
    </>
  );

  const freqQuestions = (
    //Smarth Bajaj - Task #24 - 02/Sept/2020 - Added Back button
    // Hammad Tahir - Responsive Layout/About View - 170621 - Hiddedn component added to hide back arrow on breakpoint
    <>
      <div style={{ position: 'relative' }}>
        <Hidden mdDown>
          <IconButton
            style={{ position: 'absolute', top: 100, left: 100 }}
            onClick={() => setActive(0)}>
            <ArrowBackIos style={{ fill: '#692B40' }} fontSize="large" />
          </IconButton>
        </Hidden>
      </div>
      <Iframe
        url="https://journi.aihealth.tech/faq"
        className={classes.frameContainer}
        allowFullScreen={true}
      />
    </>
  );

  const privacyQues = (
    //Smarth Bajaj - Task #24 - 02/Sept/2020 -- Added Back button
    // Hammad Tahir - Responsive Layout/About View - 170621 - Hiddedn component added to hide back arrow on breakpoint
    <>
      <div style={{ position: 'relative' }}>
        <Hidden mdDown>
          <IconButton
            style={{ position: 'absolute', top: 100, left: 100 }}
            onClick={() => setActive(0)}>
            <ArrowBackIos style={{ fill: '#692B40' }} fontSize="large" />
          </IconButton>
        </Hidden>
      </div>
      <Iframe
        url="https://journi.aihealth.tech/privacy-policy/"
        className={classes.frameContainer}
        allowFullScreen={true}
      />
    </>
  );

  return (
    <React.Fragment>
      {active === 0
        ? MainPage
        : active === 1
        ? useJiemba
        : active === 2
        ? freqQuestions
        : active === 3
        ? privacyQues
        : undefined}
    </React.Fragment>
  );
};

export default About;
