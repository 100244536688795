import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SuggestionRootType, Suggestion } from 'types/suggestion';

const initialState: SuggestionRootType = {
  loading: false,
  suggestions: [],
  queue: [], //Hammad Tahir - suggesting story or safety plan items - 101021 - show suggested areas
};

const suggestionSlice = createSlice({
  name: 'suggest',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ suggestions: Suggestion[] }>) {
      const { suggestions } = action.payload;
      state.suggestions = suggestions;
    },
    //Hammad Tahir - suggesting story or safety plan items - 101021 - start
    add(state, action: PayloadAction<{ suggestion: Suggestion }>) {
      const { suggestion } = action.payload;
      state.suggestions = state.suggestions.concat(suggestion);
    },
    addQueue(state, action: PayloadAction<{ suggestion: Suggestion }>) {
      const { suggestion } = action.payload;
      state.queue = state.queue.concat(suggestion);
    },
    remove(state, action: PayloadAction<{ id: String }>) {
      const id = action.payload.id;
      state.suggestions = state.suggestions.filter(item => item.SuggestionId !== id)
    },
    removeQueue(state, action: PayloadAction<{ id: String }>) {
      const id = action.payload.id;
      state.queue = state.queue.filter(item => item.SuggestionId !== id)
    },
    //Hammad Tahir - suggesting story or safety plan items - 101021 - End
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    }
  }
});

//Hammad Tahir - suggesting story or safety plan items - 101021
export const { startLoading, stopLoading, fetch, add, addQueue, remove, removeQueue } = suggestionSlice.actions;
export default suggestionSlice.reducer;
