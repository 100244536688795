import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { Suggestion, GoalInfo } from 'types/suggestion';
import {
  stopLoading as stopGoalLoading,
  startLoading as startGoalLoading
} from 'slices/goal/goalSlice';
import { stopLoading, startLoading, fetch, add, remove, removeQueue, addQueue } from './suggestionSlice';
//Hammad Tahir - suggesting story or safety plan items - 101021 - commented following block
// import {
//   stopLoading as storyStopLoading,
//   startLoading as storyStartLoading
// } from 'slices/story/storySlice';
// import {
//   stopLoading as safetyStopLoading,
//   startLoading as safetyStartLoading
// } from 'slices/safety/safetySlice';
import { FocusArea } from 'types/other';
import uuid from 'uuid'; //Hammad Tahir - suggesting story or safety plan items - 101021

//** ASYNC FUNCS */
//Hammad Tahir - suggestGoalFix2 - 101021
//dont show loader when suggesting goal
export const suggestGoal = (
  history: any,
  goal: GoalInfo
): AppThunk => async dispatch => {
  try {
    // dispatch(startLoading());
    const suggestion: Suggestion = {
      SuggestionId: '',
      RecoveryPlanId: sessionStorage.getItem('RecoveryPlanId')!,
      SuggestedByUserId: '',
      Name: 'Goal Suggestion',
      ExtraInfo: '',
      GroupName: 'Goals',
      GoalInfo: goal,
      AcceptedOn: '',
      RejectedOn: ''
    };

    await dispatch(add({suggestion: suggestion}));
    history.push('/goals/current');
    await callSuggestionServiceProviderCreate(suggestion);
    await dispatch(fetchAllSuggestions());
    

    // dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - suggestGoalFix2 - 101021
//dont show loader when deleting goal suggestion
export const deleteSuggestionGoalFromList = (
  id: string
): AppThunk => async (dispatch, getState) => {
  try {
    // dispatch(startGoalLoading());
    await dispatch(addQueue({suggestion: 
      getState().suggestion.suggestions.find(item => 
        item.SuggestionId === id)!}));
    await dispatch(remove({id: id}))
    await callSuggestionServiceProviderDelete(id);
    await dispatch(removeQueue({id: id}));
    setTimeout(async () => {
        if (getState().suggestion.queue.length === 0) {
          await dispatch(fetchAllSuggestions());
        }
      }, 2000)

    // dispatch(stopGoalLoading());
  } catch (err) {
    // dispatch(stopGoalLoading());
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - suggesting story or safety plan items - 101021
//create a queue of suggestion items
//add item to queue before creating suggestion, remove item from queue after suggestion is cerated
//fetch all suggestions when queue is empty
export const suggestStrength = (value: string): AppThunk => async (dispatch, getState) => {
  try {
    // dispatch(storyStartLoading());

    const suggestion: Suggestion = {
      SuggestionId: uuid(),
      RecoveryPlanId: sessionStorage.getItem('RecoveryPlanId')!,
      SuggestedByUserId: '',
      Name: value,
      ExtraInfo: '',
      GroupName: 'Strengths',
      GoalInfo: null,
      AcceptedOn: '',
      RejectedOn: ''
    };

    await dispatch(add({suggestion: suggestion}));
    await dispatch(addQueue({suggestion: suggestion}));
    await callSuggestionServiceProviderCreate(suggestion);
    await dispatch(removeQueue({id: suggestion.SuggestionId}));
    getState().suggestion.queue.length === 0 &&
      await dispatch(fetchAllSuggestions());

    // dispatch(storyStopLoading());
  } catch (err) {
    // dispatch(storyStopLoading());
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - suggesting story or safety plan items - 101021
//create a queue of suggestion items to be removed
//add item to queue before removing suggestion, remove item from queue after suggestion is removed
//fetch all suggestions when queue is empty
export const deleteSuggestionStoryFromList = (
  id: string
): AppThunk => async (dispatch, getState) => {
  try {
    // dispatch(storyStartLoading());
    
    await dispatch(addQueue({suggestion: 
      getState().suggestion.suggestions.find(item => 
        item.SuggestionId === id)!}));
    await dispatch(remove({id: id}))
    await callSuggestionServiceProviderDelete(id);
    await dispatch(removeQueue({id: id}));
    setTimeout(async () => {
        if (getState().suggestion.queue.length === 0) {
          await dispatch(fetchAllSuggestions());
        }
      }, 2000)

    // dispatch(storyStopLoading());
  } catch (err) {
    // dispatch(storyStopLoading());
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - suggesting story or safety plan items - 101021
//create a queue of suggestion items
//add item to queue before creating suggestion, remove item from queue after suggestion is cerated
//fetch all suggestions when queue is empty
export const suggestFocusAreas = (
  suggestedArea: FocusArea
): AppThunk => async (dispatch, getState) => {
  try {
    // dispatch(storyStartLoading());

    const suggestion: Suggestion = {
      SuggestionId: suggestedArea.name,
      RecoveryPlanId: sessionStorage.getItem('RecoveryPlanId')!,
      SuggestedByUserId: '',
      Name: suggestedArea.id,
      ExtraInfo: '',
      GroupName: 'FocusAreas',
      GoalInfo: null,
      AcceptedOn: '',
      RejectedOn: ''
    };

    await dispatch(add({suggestion: suggestion}))
    await dispatch(addQueue({suggestion: suggestion}))
    await callSuggestionServiceProviderCreate(suggestion);
    await dispatch(removeQueue({id: suggestion.SuggestionId}))
    getState().suggestion.queue.length === 0 && 
      await dispatch(fetchAllSuggestions());

    // dispatch(storyStopLoading());
  } catch (err) {
    // dispatch(storyStopLoading());
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - suggesting story or safety plan items - 101021
//create a queue of suggestion items
//add item to queue before creating suggestion, remove item from queue after suggestion is cerated
//fetch all suggestions when queue is empty
export const suggestSafetyPlan = (
  value: string,
  groupName:
    | 'StayWell'
    | 'StressMe'
    | 'WarningSigns'
    | 'Strategies'
    | 'UnwellHappen'
    | 'UnwellNotHappen'
    | 'WhenUnwellNotice'
    | 'Services',
  extraInfo: string
): AppThunk => async (dispatch, getState) => {
  try {
    // dispatch(safetyStartLoading());

    const suggestion: Suggestion = {
      SuggestionId: uuid(),
      RecoveryPlanId: sessionStorage.getItem('RecoveryPlanId')!,
      SuggestedByUserId: '',
      Name: value,
      ExtraInfo: extraInfo,
      GroupName: groupName,
      GoalInfo: null,
      AcceptedOn: '',
      RejectedOn: ''
    };

    await dispatch(add({suggestion: suggestion}))
    await dispatch(addQueue({suggestion: suggestion}))
    await callSuggestionServiceProviderCreate(suggestion);
    await dispatch(removeQueue({id: suggestion.SuggestionId}))
    getState().suggestion.queue.length === 0 && 
      await dispatch(fetchAllSuggestions());

    // dispatch(safetyStopLoading());
  } catch (err) {
    // dispatch(safetyStopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const fetchAllSuggestions = (): AppThunk => async dispatch => {
  try {
    const suggestions = await callSuggestionServiceProviderList();
    dispatch(
      fetch({
        suggestions: suggestions.filter(
          item => !item.RejectedOn && !item.AcceptedOn && item.SuggestedByUserId === sessionStorage.getItem('Provider_UserId') //Hammad Tahir - minorFixes - 190721 - Only show suggestions from current SP
        )
      })
    );
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - suggesting story or safety plan items - 101021
//create a queue of suggestion items
//add item to queue before creating suggestion, remove item from queue after suggestion is cerated
//fetch all suggestions when queue is empty
export const deleteSuggestionFromList = (
  id: string
): AppThunk => async (dispatch, getState) => {
  try {
    // dispatch(safetyStartLoading());

    await dispatch(addQueue({suggestion: 
      getState().suggestion.suggestions.find(item => 
        item.SuggestionId === id)!}));
    await dispatch(remove({id: id}));
    await callSuggestionServiceProviderDelete(id);
    await dispatch(removeQueue({id: id}));
    setTimeout(async () => {
        if (getState().suggestion.queue.length === 0) {
          await dispatch(fetchAllSuggestions());
        }
      }, 2000)

    // dispatch(safetyStopLoading());
  } catch (err) {
    // dispatch(safetyStopLoading());
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */

const callSuggestionServiceProviderCreate = (suggestion: Suggestion) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post('/Suggestion/ServiceProvider/Create', suggestion);
};

const callSuggestionServiceProviderList = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios
    .get(
      `/Suggestion/ServiceProvider/List/${sessionStorage.getItem(
        'RecoveryPlanId'
      )}`
    )
    .then(response => {
      const suggestions: Suggestion[] = JSON.parse(
        JSON.stringify(response.data)
      );
      return suggestions;
    });
};

const callSuggestionServiceProviderDelete = (id: string) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.delete(`/Suggestion/ServiceProvider/Delete/${id}`);
};
