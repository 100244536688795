import React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundColor: '#C57D7D'
  },
  body: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '15px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0'
  },
  text: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#FFFFFF',
    margin: '8px 10px',
    cursor: 'pointer',
    '&:hover': {
      color: '#B7C38C'
    }
  },
  contentTitle: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFFFFF',
    margin: '8px 10px'
  }
}));

type Props = {
  close: () => void;
};

const UseJiembaFor: React.FC<Props> = ({ close }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAYCAYAAAAF6fiUAAAABmJLR0QA/wD/AP+gvaeTAAAExUlEQVRoge2abWiVZRjH//cy03ALteacyxU0W+RLRZRSzvk21gv1oYLeyNL6UJRZkVRGpVCNgX5Q8EMEtaiggYmVLYWYmZKBkCT5QcK0LNYmazrT6Zy/Pjz32HXunZ2d5+ycbeH+cDjP87/+131f9/Xcz/Xc93OONIIBA5gONAGHgXuGOp4LDsAGenAaKE/XNy+XgV1AuM0cj5F0Q7qOo+L0AhRKmi9psqQJkib6b/sJcVJSs/+0SOqUNEnSVEnjfAz5ki6SVOB9/pW0TdKLzrkTcWIcIvwl6UZ/3CFpZ9Z7AC4HjjK4eDXrA8kBgAnA28Bm4M5cdbJ4kJN/BpiXk8EMI7g4YmClpNWK6pxFh6Q/Fd2KZzyXr6i82BJT4r8tuv1a/OeYpEOStjrnjsSJL1MAt0o64JxrT2IrlLRU0lFJnzjnMLY8SbWKSudy51zHYAR7GVAJLAJm+gDT9X08mOXrchlrGvFMAr72sez2nANWAWuAt4A2E+87gf8SY3tjaEYRA/6iWTybhk8hMD4HscwgWrd34yvPP5KiLB4M2mg0tg8ziWOol6F99g/cBOyS1CSpGfi2v7vNz94aYDtQFtiuBPYAbwKPStolqdSbd0h6iKikrErRxWnTXomkCmP7KVVswwJJ7oDn+9A9QfQQDlEX6Kb5pH4AjCZxobAj0K7uY1ZvB8Z6zb0pZj/Ap6a9FYEt7c3XkIHotrd4IYnm9UDzHFDnj5sCbYPRTQF2mvP6QLs3SUL3AwVG80M/F+A1o/3e8IOyWLCDKQLS3u0Zv9JgQC8F9jD5m4jKynp/3m60c4yuCXg48H3SaCcD5wN7K3CV0SxMkvDjwfldpr0uw9dklMhMADwGtPuOF8T0nRYM6GVjqwqSdBj/8KXnYXfI6L8w2nYTUzeKjXZpkuQ+EMS2LbD/CtwfcCVeuyzgp2eaz1gg2g13mo6fiek/JwjcXoB9ga3a8wUmuY2em0HvGW0RrlY2BfbPA3t50F4nMBuoNlwb4Ly+3vD7M82nFH8V1CbpN3O+Epgaw//m4LxVkoD5kmYZfotz7ht//LSid0aS1OC/lyj1JnJ39wFwiaTFxnZcUrj8XRa0t9Y5t0fSaMPtM5uwSsNvTRFH9gFcBxwzM+AIMKt/TwnYEszERZ7fbLjzwPWeLwJaPH8WKPX8LylmP8B602dVYHsliOli4G9j/9lfNAHXACc9v8JzLmjvvuxkNgaI1uj/mCDa6eeHCKKycc74nCMqL1MC/kuvzyNxlbPBtNUYJOEscMCcbzTadYZvBfKDuMYAJ7y9A5gZ2MuISpEz3CnT5tUDzWdGAG4hcZvehanpgXYcvWt8d6LvCPh5nl9ruD+AK0x7lfQ8F5qBBUQX86Dn6ozWLk3X9BFfBfA+UJXm2Df69vbGyVnWQe/tPMDHQJHRFAM/Bpou4HZvnxvYyulZ90M0O3uVOGA80YNyrOHKgI+AhYZ717dzHJiYpXE7P/b8/tU5BtELrXADcwr4DKilp4Zb1Bj/S0ksZxbtwNwsxFgBXDvQdoYtgFFEbxA7+kikRS2mnnr/ahLLGUQ1ffZQjel/CaJ/BzSQHL8Dd6fwLQaWA+8BDwKxfi4dgQHRKqmeqBR9BzyFX9qNIBH/AYy6FGkTrdypAAAAAElFTkSuQmCC"
          alt=""
        />
        <IconButton onClick={close}>
          <Close fontSize="large" style={{ fill: '#FFFFFF' }} />
        </IconButton>
      </div>
      <div className={classes.body}>
        <div className={classes.content}>
          <span className={classes.contentTitle}>Use Journi for...</span>
          <span
            className={classes.text}
            onClick={() =>
              window.open('https://journi.aihealth.tech/wellbeing', '_blank')
            }>
            Your own wellbeing
          </span>
          <span
            className={classes.text}
            onClick={() =>
              window.open('https://journi.aihealth.tech/caring', '_blank')
            }>
            Caring for others
          </span>
          <span
            className={classes.text}
            onClick={() =>
              window.open(
                'https://journi.aihealth.tech/service-provider',
                '_blank'
              )
            }>
            Providing services
          </span>
        </div>
        <div className={classes.content}>
          <span className={classes.contentTitle}>Information</span>
          <span
            className={classes.text}
            onClick={() =>
              window.open('https://journi.aihealth.tech/faq', '_blank')
            }>
            FAQ
          </span>
          <span
            className={classes.text}
            onClick={() =>
              window.open('https://journi.aihealth.tech/howtojourni', '_blank')
            }>
            How to Journi
          </span>
          <span
            className={classes.text}
            onClick={() =>
              window.open('https://journi.aihealth.tech/about', '_blank')
            }>
            About Us
          </span>
        </div>
      </div>
    </div>
  );
};

export default UseJiembaFor;
